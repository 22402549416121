const apiUrl = process.env.API_URL || 'https://snappy-run-436619-b5.ew.r.appspot.com'; 

// Ostatak tvog koda
fetch(`${apiUrl}/api/some-endpoint`)
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error('Error:', error));


const home = document.querySelector(".home");
const loginBtn  = document.querySelector("#loginBtn");//glavni gumb u headeru
const logoutBtn = document.querySelector("#logoutBtn"); //glavni gum u headeru
const formContainer = document.querySelector(".form_container");
const formOpenBtn = document.querySelector("#loginBtn");
const formCloseBtn = document.querySelector(".form_close");
const signupform = document.querySelector("#signup_form_link");
const loginform = document.querySelector("#login_form_link");
const pwShowHide = document.querySelectorAll(".pw-hide");
const forgotPasswordLink = document.querySelector("#forgot-password");
const forgotPasswordForm = document.querySelector(".forgot_password_form");
const resetPasswordForm = document.querySelector(".reset_password_form");
/*const forgotPasswordBtn = document.querySelector("#reset-password-btn");*/
const resetPasswordBtn = document.querySelector("#set-new-password-btn");
const connectWalletBtn = document.querySelector("#connectWallet");
const loginFormm = document.querySelector('#login_formm');
const signupFormm = document.querySelector('#signup_formm');
const loginNowBtn = document.querySelector('#login_now');
const winnersNavItem = document.getElementById('#winnersNavItem');
const winnersSection = document.getElementById('#winners-section');
const contactNavItem = document.getElementById('#contactNavItem');
const contactSection = document.getElementById('#contact-section');
const aboutNavItem = document.getElementById('#aboutNavItem');
const aboutSection = document.getElementById('#about-section');
const addressElement = document.getElementById('crypto-address');
const timerElement = document.getElementById('timer');
const totalCollectedElement = document.getElementById('total-collected');
const ticketButtons = document.querySelectorAll('.ticket-button');
console.log('Selected ticket buttons:', ticketButtons);


import './style.css'; 

// src/script.js
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { v4 as uuidv4 } from 'uuid';

// Polyfill for crypto.randomUUID
if (typeof crypto !== 'undefined' && typeof crypto.randomUUID !== 'function') {
  crypto.randomUUID = function() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };
}

const generateUUID = () => {
    if (typeof crypto !== 'undefined' && crypto.randomUUID) {
        return crypto.randomUUID();
    } else {
        return uuidv4();  // fallback to uuidv4 if crypto.randomUUID is not supported
    }
};

const myUUID = generateUUID();
console.log('Generated UUID on the client side:', myUUID);


// Možeš koristiti UUID u aplikaciji kao što je dodavanje jedinstvenih ID-eva HTML elementima
// document.getElementById('someElement').id = myUUID;


    //**********progress bar************//
        
    //boja loga
    document.querySelectorAll('.nav_logo span').forEach(span => {
        span.addEventListener('mouseover', () => {
        span.style.color = '#FFD700'; // Change this to your desired hover color
        });
        span.addEventListener('mouseout', () => {
        span.style.color = ''; // Reset to original color
        });
    });

    
    // progressbar
    const progress = document.getElementById('progressbar');
    const totalHeight = document.body.scrollHeight - window.innerHeight;

    window.onscroll = function() {
        // Koristi scrollY umjesto pageYOffset
        let progressHeight = (window.scrollY / totalHeight) * 100;
        progress.style.height = progressHeight + "%";
    };



  

    /****hamburger menu***/

    document.addEventListener('DOMContentLoaded', function() {
        const hamburger = document.getElementById('hamburger');
        const mobileMenu = document.getElementById('mobileMenu');
        
        hamburger.addEventListener('click', function() {
          hamburger.classList.toggle('active');
          mobileMenu.classList.toggle('active');
        });
      });  

     
// Funkcija koja zatvara formu kada se klikne izvan nje
   /* function closeFormOnOutsideClick(formSelector) {
        document.addEventListener('click', function(event) {
            const formElement = document.querySelector(formSelector);
            if (formElement && !formElement.contains(event.target) && !event.target.matches('.login_link, .signup_link')) {
                formElement.style.display = 'none';
            }
        });
    }*/

// Funkcija za zatvaranje forme prilikom klika izvan nje
    function closeFormOnOutsideClick(formSelector) {
        document.addEventListener('click', function(event) {
            const formElement = document.querySelector(formSelector);
            const formContainer = document.querySelector('.form_container');

            // Provjeri da li je kliknut izvan forme i izvan container-a
            if (formElement && !formElement.contains(event.target) && !formContainer.contains(event.target) && !event.target.matches('.form_container')) {
                formElement.style.display = 'none';
            }
        });
    }

    // Zatvaranje formi prilikom klika izvan njih
    //closeFormOnOutsideClick('.form_container');
    //closeFormOnOutsideClick('.signup_form');
    //closeFormOnOutsideClick('.login_form');
    

    document.getElementById('signup_form_link').addEventListener('click', function(event) {
        event.preventDefault(); // Sprečava zadano ponašanje linka
        document.querySelector('.signup_form').style.display = 'block'; // Prikazuje SignUp formu
        document.querySelector('.login_form').style.display = 'none'; // Sakriva Login formu
    });
    
    document.getElementById('login_form_link').addEventListener('click', function(event) {
        event.preventDefault(); // Sprečava zadano ponašanje linka
        document.querySelector('.login_form').style.display = 'block'; // Prikazuje Login formu
        document.querySelector('.signup_form').style.display = 'none'; // Sakriva SignUp formu
    });
    
    


        /*****QR CODE CONTACT SECTION*****/

        //document.querySelector('.qr_code').addEventListener('mouseover', () => {
           // alert('Scan this QR code to connect with us on Telegram!');
          //});


     //********LOGIRANJE**********/

     // Function to update UI elements based on login status
    function updateUIOnLoginStatus(isLoggedIn) {
        if (isLoggedIn) {
            loginBtn.style.display = 'none';
            logoutBtn.style.display = 'inline-block';
            connectWalletBtn.style.display='inline-block';
        } else {
            loginBtn.style.display = 'inline-block';
            logoutBtn.style.display = 'none';
            connectWalletBtn.style.display='none';
        }
    }


    // On page load, check if user is logged in and update UI
    window.onload = async function() {
        const token = getTokenFromCookie();
        if (token) {
            updateUIOnLoginStatus(true); // User is logged in
        } else {
            updateUIOnLoginStatus(false); // User is not logged in
        }
    };


    logoutBtn.addEventListener("click", async () => {
        try {
            const token = getTokenFromCookie();
    
            // Poziv backendu za odjavu
            const response = await fetch('apiUrl/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.ok) {
                // Ukloni token iz kolačića
                deleteTokenFromCookie();
    
                // Ukloni token iz crne liste
                const blacklistResponse = await fetch('apiUrl/blacklist/remove', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: token })
                });
    
                if (blacklistResponse.ok) {
                    updateUIOnLoginStatus(false); // Ažuriraj UI nakon uspješne odjave
                    alert('Uspješno ste se odjavili');
                    // Ovdje možete dodati redirekciju ili ažuriranje stranice ako je potrebno
                } else {
                    handleBlacklistError(blacklistResponse);
                }
            } else {
                handleLogoutError(response);
            }
        } catch (error) {
            console.error('Greška:', error);
            alert('Greška pri odjavi');
        }
    });
    
    function deleteTokenFromCookie() {
        document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        // Osvježavanje stranice
        location.reload();
    }

    // Postavite timer na 1 sat (60 minuta)
    setTimeout(deleteTokenFromCookie, 60 * 60 * 1000);
    
    function handleBlacklistError(response) {
        if (response.headers.get('content-type') && response.headers.get('content-type').includes('application/json')) {
            response.json().then(data => {
                alert('Greška prilikom uklanjanja tokena iz crne liste: ' + data.message);
            }).catch(err => {
                console.error('Unexpected response:', err);
                alert('Greška: Neočekivan odgovor od servera');
            });
        } else {
            response.text().then(errorText => {
                console.error('Unexpected response:', errorText);
                alert('Greška: Neočekivan odgovor od servera');
            }).catch(err => {
                console.error('Unexpected response:', err);
                alert('Greška: Neočekivan odgovor od servera');
            });
        }
    }
    
    function handleLogoutError(response) {
        if (response.headers.get('content-type') && response.headers.get('content-type').includes('application/json')) {
            response.json().then(data => {
                alert('Greška: ' + data.message);
            }).catch(err => {
                console.error('Unexpected response:', err);
                alert('Greška: Neočekivan odgovor od servera');
            });
        } else {
            response.text().then(errorText => {
                console.error('Unexpected response:', errorText);
                alert('Greška: Neočekivan odgovor od servera');
            }).catch(err => {
                console.error('Unexpected response:', err);
                alert('Greška: Neočekivan odgovor od servera');
            });
        }
    }
    


    // Function to check if user is logged in
    function isLoggedIn() {
        const token = getTokenFromCookie();
        return token !== null;
    }

    // Funkcija za dohvaćanje JWT tokena iz cookiea
    function getTokenFromCookie() {
        const name = 'jwt' + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    }




   

    // Otvaranje i zatvaranje obrasca
    formOpenBtn.addEventListener("click", () => home.classList.add("show"));
    formCloseBtn.addEventListener("click", () => home.classList.remove("show"));

    // Funkcionalnost prikazivanja/skrivanja lozinke
    pwShowHide.forEach((icon) => {
        icon.addEventListener("click", () => {
            const getPwInput = icon.parentElement.querySelector("input");
            getPwInput.type = getPwInput.type === "password" ? "text" : "password";
            icon.classList.toggle("uil-eye-slash");
            icon.classList.toggle("uil-eye");
        });
    });

    //Prikazivanje obrasca za registraciju
    signupform.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.add("active");
    });  //radi

     //Prikazivanje obrasca za logiranje
     loginform.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.remove("active");
    }); //radi



    //Prikazivanje obrasca za prijavu ili odjavu
    /*loginBtn.addEventListener("click", (e) => {
        e.preventDefault();
        // Provjera je li korisnik prijavljen
        if (isLoggedIn()) {
            // Ako je prijavljen, izvrši odjavu
            logoutUser();
        } else {
            // Ako nije prijavljen, prikaži obrazac za prijavu
            formContainer.classList.remove("active");
        }
    });*/

    document.addEventListener("DOMContentLoaded", () => {
        const loginBtn = document.querySelector("#loginBtn");
        loginBtn.addEventListener("click", (e) => {
            e.preventDefault();
            console.log("Login button clicked");
    
            if (isLoggedIn()) {
                logoutUser(); // Ako je prijavljen, izvrši odjavu
            } else {
                formContainer.classList.remove("active"); // Prikaži obrazac za prijavu
            }
        });
    });
    


    // Slanje obrasca za registraciju
    document.querySelector(".signup_form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const email = this.querySelector('input[type="email"]').value;
        const password = this.querySelectorAll('input[type="password"]')[0].value;
        try {
            const response = await fetch('apiUrl/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ username: email, password: password })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error registering user');
        }
    });

    // Prikazivanje obrasca za zaboravljenu lozinku
    forgotPasswordLink.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.add("active-forgot");
        formContainer.classList.remove("active");
    });

    // Slanje obrasca za zaboravljenu lozinku
    forgotPasswordForm.querySelector("form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const email = this.querySelector('input[type="email"]').value;
        try {
            const response = await fetch('apiUrl/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending password reset email');
        }
    });

    // Slanje obrasca za resetiranje lozinke
    resetPasswordBtn.addEventListener("click", async function (event) {
        event.preventDefault();
        const token = new URLSearchParams(window.location.search).get('token');
        const newPassword = resetPasswordForm.querySelectorAll('input[type="password"]')[0].value;
        const confirmPassword = resetPasswordForm.querySelectorAll('input[type="password"]')[1].value;


        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            const response = await fetch('apiUrl/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token, newPassword: newPassword })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
                // Redirect to login page or perform other appropriate action
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error resetting password');
        }
    });

    //Prikazivanje obrasca za registraciju
    signupFormm.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.add("active");
    });  //radi

    document.addEventListener("DOMContentLoaded", () => {    
        loginFormm.addEventListener('submit', (event) => {
            event.preventDefault(); // Prevents page reload
    
            const email = document.querySelector('#email').value;
            const password = document.querySelector('#password').value;
    
            loginUser(email, password); // Call the login function
        });
    });
    
    // Update the event listener for the Login Now button
    loginNowBtn.addEventListener("click", async function (event) {
        event.preventDefault(); // Prevents form submission

        const email = document.querySelector('#login_form input[type="email"]').value;
        const password = document.querySelector('#login_form input[type="password"]').value;

        loginUser(email, password);
    });

    // Function to log in the user
 async function loginUser(username, password) {
    try {
        console.log('Logging in with username:', username);
        const response = await fetch('apiUrl/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ username: username, password: password })
        });
        if (response.ok) {
            const data = await response.json();
            console.log('Login successful, received data:', data);
            saveTokenToCookie(data.token); // Save token to cookie
            updateUIOnLoginStatus(true); // Update UI to reflect logged-in state
            alert('Successfully logged in');
            formContainer.classList.remove("show");
            document.querySelector('.home').classList.remove("show");
        } else {
            const errorData = await response.json();
            console.log('Error during login:', errorData);
            alert('Error: ' + errorData.message);
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Error logging in');
    }
 }

    // Function to save token to cookie
    function saveTokenToCookie(token) {
        document.cookie = `jwt=${token}; path=/;`;
    }


    // Provjera i postavljanje gumba na temelju prisutnosti JWT tokena
    document.addEventListener('DOMContentLoaded', () => {
        const token = getTokenFromCookie();

        if (token) {
            loginBtn.textContent = 'Logout'; // Ako je korisnik prijavljen
            connectWalletBtn.style.display = 'block'; // Prikazivanje gumba za povezivanje novčanika
        } else {
            loginBtn.textContent = 'Login'; // Ako korisnik nije prijavljen
            connectWalletBtn.style.display = 'none'; // Sakrivanje gumba za povezivanje novčanika
        }
    });





    // Function to log out the user
    async function logoutUser() {
        try {
            const response = await fetch('apiUrl/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromCookie()}`
                }
            });
            if (response.ok) {
                // Update user interface and remove token from cookie
                loginBtn.textContent = 'Login';
                deleteTokenFromCookie(); // Delete token from cookie
                alert('Successfully logged out');
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error logging out');
        }
    }


    // Show login or logout form
    loginBtn.addEventListener("click", (e) => {
        e.preventDefault();
        console.log('Login button clicked'); // Provjera klikne li gumb
        // Check if user is logged in
        if (isLoggedIn()) {
            // If logged in, log out
            logoutUser();
        } else {
            // If not logged in, show login form
            formContainer.classList.remove("active");
            formContainer.classList.add("active-login");
        }
    });
    

    // Submit login form
    /*document.querySelector(".login_now").addEventListener("submit", async function (event) {
        event.preventDefault();
        const username = this.querySelector('input[type="email"]').value;
        const password = this.querySelectorAll('input[type="password"]')[0].value;
        loginUser(username, password);
    });
*/
    // Submit forgot password form
    forgotPasswordForm.querySelector("form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const email = this.querySelector('input[type="email"]').value;
        try {
            const response = await fetch('apiUrl/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending password reset email');
        }
    });

    // Submit reset password form
    resetPasswordForm.querySelector("form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const token = new URLSearchParams(window.location.search).get('token');
        const newPassword = this.querySelectorAll('input[type="password"]')[0].value;
        const confirmPassword = this.querySelectorAll('input[type="password"]')[1].value;
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            const response = await fetch('apiUrl/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token, newPassword: newPassword })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
                // Redirect to login page or perform other appropriate action
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error resetting password');
        }
    });



    // Function to get JWT token from cookies
     function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }




////////////WALLET CONNECT PART/////////////

import Web3 from 'web3';

// Funkcija za povezivanje s novčanikom
async function connectWallet() {
    const token = getCookie('jwt'); // Provjerite funkciju getCookie

    // Provjerite postoji li MetaMask
    if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask to use this feature.');
        return;
    }

    try {
        // Traženje pristupa korisničkim računima
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum); // Koristimo window.ethereum za inicijalizaciju Web3
        const accounts = await web3.eth.getAccounts(); // Dobivanje računa
        alert('Connected account: ' + accounts[0]);

        console.log('Sending userAddress to server:', accounts[0]);
        const response = await fetch('apiUrl/api/connectWallet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ userAddress: accounts[0] }),
        });

        const data = await response.json();
        if (data.success) {
            console.log('Wallet connected and data saved');
        } else {
            console.error('Error saving wallet data');
        }
    } catch (error) {
        console.error('User denied account access or there was an error', error);
        if (error.code === 4001) {
            alert('Request was rejected. Please enable access to the wallet.');
        } else {
            alert('There was an error connecting to the wallet.');
        }
    }
}

// Funkcija za univerzalno povezivanje s novčanikom
async function universalConnect() {
    console.log('universalConnect function triggered');

    try {
        await connectWallet();
    } catch (error) {
        console.error('Error connecting to wallet:', error);
        alert('An error occurred while trying to connect to the wallet.');
    }
}

// Event listener za povezivanje s novčanikom na klik
document.getElementById('connectWallet').addEventListener('click', universalConnect);

function getCookiewallet(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
}


  
  
//-----------------COUNTER SECTION--------------//

let currentIndex = 0;  // Za praćenje trenutačnog indeksa za prikazivanje adresa
let totalCollected = 0;
let timer;
let timerRunning = false;
const baseSpeed = 30000; // Osnovna brzina od 30 sekundi
let rotationInterval;
let addressesAdded = [];
let finalAddress = ''; // Varijabla za čuvanje finalne adrese

const COINGECKO_API_URL = 'http://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';

async function getEthPrice() {
    try {
        const response = await fetch(COINGECKO_API_URL);
        if (!response.ok) {
            throw new Error('Failed to fetch ETH price');
        }
        const data = await response.json();
        return data.ethereum.usd;
    } catch (error) {
        console.error('Error fetching ETH price:', error);
        return null;
    }
}

async function convertUsdToEth(usdAmount) {
    const ethPrice = await getEthPrice();
    if (ethPrice === null) {
        throw new Error('Failed to convert USD to ETH due to missing ETH price');
    }
    return usdAmount / ethPrice;
}

function calculateSpeed() {
    return baseSpeed / Math.exp(addressesAdded.length / 100); // Eksponencijalni rast brzine
}


let timerStarted = false;//---dodano za 5 min test

async function handlePayment(usdAmount) {
    const token = getCookie('jwt'); // Provjerite funkciju getCookie
    console.log('handlePayment called with:', usdAmount);

    if (typeof window.ethereum === 'undefined') {
        alert('Please install and connect MetaMask!');
        return;
    }

    const web3 = new Web3(window.ethereum);

    try {
        console.log('Requesting MetaMask accounts...');
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        console.log('Accounts:', accounts);

        const ethAmount = await convertUsdToEth(usdAmount);
        console.log('ETH Amount:', ethAmount);

        const ethAmountRounded = parseFloat(ethAmount.toFixed(18));
        const ethAmountWei = web3.utils.toWei(ethAmountRounded.toString(), 'ether');
        console.log('ETH Amount in Wei:', ethAmountWei);

        const balanceWei = await web3.eth.getBalance(accounts[0]);
        console.log('Wallet Balance in Wei:', balanceWei);

        if (BigInt(balanceWei) < BigInt(ethAmountWei)) {
            alert('You do not have enough funds in your wallet. Please top up your wallet and try again.');
            return;
        }

        if (confirm(`Do you want to proceed with the payment of ${ethAmountRounded} ETH?`)) {
            console.log('Sending transaction...');
            const receipt = await web3.eth.sendTransaction({
                from: accounts[0],
                to: '0xEAb7Be63c58DC0D6C181aBEf0785F74409097422', // Adresa primatelja
                value: ethAmountWei
            });

            console.log('Transaction receipt:', receipt);

            for (let i = 0; i < usdAmount; i++) {
                addressesAdded.push(accounts[0]);
            }
            console.log('Addresses added:', addressesAdded);

            totalCollected += usdAmount;
            updateDisplay();

            alert('Payment successful! Transaction hash: ' + receipt.transactionHash);

            // Pokrećemo timer samo ako već nije pokrenut
            if (!timerStarted) {
                startTimer(5 * 60); // Pokrenite timer na 5 minuta
                timerStarted = true; // Označavamo da je timer pokrenut
            } //maknuti naknon testa od 5min

            console.log('Sending request to /api/saveTransaction:', {
                walletAddress: accounts[0],
                paymentValue: usdAmount
            });

            try {
                const response = await fetch('apiUrl/api/saveTransaction', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        walletAddress: accounts[0],
                        paymentValue: usdAmount
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.success) {
                    console.log('Transaction saved successfully');
                } else {
                    console.error('Error saving transaction:', data.message);
                }
            } catch (error) {
                console.error('Error making saveTransaction request:', error);
            }
        }
    } catch (error) {
        console.error('Payment error:', error);
        alert('Payment failed: ' + error.message);
    }    
}

// Ažuriramo event listenere za ticket buttone
document.addEventListener('DOMContentLoaded', () => {
    const ticketButtons = document.querySelectorAll('.ticket-button');
    console.log('Selected ticket buttons:', ticketButtons);

    ticketButtons.forEach(button => {
        console.log('Button found:', button);
        button.addEventListener('click', async (event) => {
            const usdValue = parseInt(event.target.getAttribute('data-value'));
            console.log('Button clicked with value:', usdValue);
            await handlePayment(usdValue);
        });
    });
});



// Funkcija za prikaz vremena u formatu HH:MM:SS
function formatTime(seconds) {
    const h = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const s = String(seconds % 60).padStart(2, '0');
    return `${h}:${m}:${s}`;
}



// Funkcija za pokretanje timera -24h
/*function startTimer(duration) {
    let start = Date.now();
    function updateTimer() {
        let diff = duration - (((Date.now() - start) / 1000) | 0);
        let hours = (diff / 3600) | 0;  
        let minutes = ((diff % 3600) / 60) | 0;
        let seconds = (diff % 60) | 0;

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        timerElement.textContent = `${hours}:${minutes}:${seconds}`;

        if (diff <= 0) {
            clearInterval(timer);
            clearInterval(rotationInterval);
            timerElement.textContent = '00:00:00';
            executePayout(); // Pozivamo funkciju za isplatu
        }
    }
    updateTimer();
    timer = setInterval(updateTimer, 1000);
}*/

// Funkcija za pokretanje timera - 5 minuta
function startTimer(duration) {
    let remainingTime = duration * 1000; // Trajanje timera u milisekundama
    let endTime = Date.now() + remainingTime; // Izračunavamo vrijeme kada će timer završiti

    function updateTimer() {
        let diff = Math.max(0, endTime - Date.now()); // Odbrojava preostalo vrijeme
        let seconds = Math.floor(diff / 1000); // Preostalo vrijeme u sekundama

        timerElement.textContent = formatTime(seconds);

        if (diff <= 0) {
            clearInterval(timer);
            timerElement.textContent = '00:00:00';
            addWinnerToSection();
            // Pozivanje funkcije za slanje emaila
            handleWinnerAndSendLink(finalAddress, calculateAmountWon());
            // Postavljamo novi timer za 5 minuta, ali ne resetiramo odmah prikaz
            setTimeout(() => {
                // Uklanjanje pobjedničke adrese iz prikaza
                removeWinnerFromDisplay();

                // Resetiranje timera na 00:05:00
                timerElement.textContent = '00:05:00';

                // Postavljanje default prikaza za crypto adresu
                addressElement.textContent = 'Addresses Will Appear Here!';

                // Pokretanje novog 5-minutnog timera
                startTimer(5 * 60); // Ponovo pokrećemo timer na 5 minuta
            }, 5 * 60 * 1000); // Čekamo 5 minuta
        }
    }
    updateTimer(); // Pozivamo jednom da odmah prikazujemo početno stanje
    timer = setInterval(updateTimer, 1000); // Postavljamo interval za ažuriranje svakih 1 sekundu
}

// Funkcija za dodavanje pobjednika u sekciju
async function addWinnerToSection() {
    const winnerAddress = finalAddress;
    const amountWon = calculateAmountWon(); // Funkcija za izračunavanje osvojenog iznosa
    const winnerNumber = winnersTableBody.children.length + 1; // Redni broj je broj postojećih redova + 1

    addWinner(winnerNumber, winnerAddress, amountWon);
    
    // Prikazujemo pobjedničku adresu još 5 minuta prije nego je uklonimo sa glavnog prikaza
    timerElement.textContent = '00:00:00'; // Ostavimo timer na 00:00:00

    // Spremanje pobjednika u bazu podataka
    try {
        const response = await fetch('apiUrl/api/save-winner', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                walletAddress: winnerAddress,
                winningValue: amountWon
            }),
        });

        if (response.ok) {
            console.log('Winner saved successfully');
        } else {
            console.error('Failed to save winner');
        }
    } catch (error) {
        console.error('Error saving winner:', error);
    }
}


//************Primjer dinamičkog dodavanja pobjednika***********//
const winnersTableBody = document.getElementById('winners-table-body');

function addWinner(number, address, amount) {
    const row = document.createElement('tr');
    row.innerHTML = `
        <td>${number}</td>
        <td>${address}</td>
        <td>$${amount}</td>
    `;
    winnersTableBody.appendChild(row);
}

// Primjer poziva funkcije za dodavanje pobjednika
    addWinner(1, '0xAbCdEf0123456789...', 1000);
    addWinner(2, '0xAbCdEf9876543210...', 500);


//SLANJE POVEZNICE ZA ISPLATU//
// Funkcija za kreiranje deep linka
async function createMetaMaskLink(winnerAddress, amountWon) {
    try {
        // Pretvaranje USD iznosa u ETH koristeći postojeću funkciju
        const amountWonETH = await convertUsdToEth(amountWon);

        // Pretvaranje iznosa u Wei jer MetaMask koristi Wei za transakcije
        const amountInWei = Web3.utils.toWei(amountWonETH.toString(), 'ether');
        
        // Kreiramo deep link za MetaMask
        const link = `https://metamask.app.link/send/${winnerAddress}?value=${amountInWei}`;
        return link;
    } catch (error) {
        console.error('Error creating MetaMask link:', error);
        return null;
    }
}

// Funkcija za slanje emaila s linkom
function sendEmailWithLink(emailAddress, link) {
    const subject = encodeURIComponent("Complete Your Transaction");
    const body = encodeURIComponent(`Click the link below to complete your transaction:\n\n${link}`);
    window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
}

// Funkcija za slanje linka kad istekne timer i prebaci se pobjednička adresa u winners sekciju
async function handleWinnerAndSendLink(winnerAddress, amountWon) {
    // Kreiranje deep linka za MetaMask, koristimo await kako bi se dobio stvarni link
    const metaMaskLink = await createMetaMaskLink(winnerAddress, amountWon);
    
    // Slanje linka na određenu email adresu putem backend servisa
    const emailAddress = "cryptoluckysprovider@gmail.com"; // Promijenite u stvarnu email adresu
    try {
        const response = await fetch('apiUrl/send-link', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ emailAddress, link: metaMaskLink }),
        });
        if (response.ok) {
            console.log('Email successfully sent');
        } else {
            console.error('Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
    }
}


// Funkcija za izračunavanje osvojenog iznosa
function calculateAmountWon() {
       // Izvlačenje iznosa iz elementa totalCollectedElement
    const totalCollectedText = totalCollectedElement.textContent;
    const amount = parseFloat(totalCollectedText.replace("Total Collected: $", ""));
    
    // Umanjivanje iznosa za 15%
    const amountAfterReduction = amount * 0.85;
    
    // Vraćanje umanjenog iznosa kao broja
    return amountAfterReduction;
}

// Funkcija za uklanjanje pobjedničke adrese iz prikaza
function removeWinnerFromDisplay() {
    finalAddress = ''; // Brišemo adresu pobjednika
    addressElement.textContent = ''; // Brišemo prikaz adrese
}

// Funkcija za ažuriranje prikaza adrese
function updateAddress() {
    if (addressesAdded.length > 0) {
        currentIndex = (currentIndex + 1) % addressesAdded.length;
        addressElement.style.opacity = 0; // Fade out
        setTimeout(() => {
            addressElement.textContent = addressesAdded[currentIndex];
            addressElement.style.opacity = 1; // Fade in
            finalAddress = addressesAdded[currentIndex]; // Ažuriramo finalAddress
        }, 500); // Duration of the fade out transition
    }
}

// Funkcija za ažuriranje prikaza ukupno sakupljenih sredstava
function updateDisplay() {
    // Ažuriramo odmah ukupno prikupljeni iznos
    totalCollectedElement.textContent = `Total Collected: $${totalCollected.toFixed(2)}`;

    // Nema potrebe za pokretanjem timera ovdje ako koristite timer od 5 minuta u drugom dijelu koda

    // Postavljamo interval za ažuriranje adresa
    const speed = calculateSpeed(); // Izračun brzine
    rotationInterval = setInterval(updateAddress, speed);

    // Postavljamo interval za redovito ažuriranje prikaza svakih 1 minut
    setInterval(() => {
        totalCollectedElement.textContent = `Total Collected: $${totalCollected.toFixed(2)}`;
    }, 60000); // 1 minuta u milisekundama

    // Ažuriramo prikaz adrese odmah
    if (addressesAdded.length > 0) {
        const addressToShow = addressesAdded[currentIndex % addressesAdded.length];
        document.getElementById('crypto-address').innerText = addressToShow;
        currentIndex++; // Povećavanje indeksa za sljedeći prikaz
    }
}



// Funkcija za spremanje transakcije dobitnika
async function saveWinnerTransaction(userId, winnerWalletAddress, winningValue, payerWalletAddress) {
    try {
        const response = await fetch('apiUrl/api/saveWinnerTransaction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('token')}`   
            },
            body: JSON.stringify({
                userId: userId,
                walletAddress: winnerWalletAddress,
                winningValue: web3.utils.fromWei(winningValue, 'ether'),
                payerWalletAddress: payerWalletAddress
            })
        });

        const data = await response.json();
        if (data.success) {
            console.log('Winner transaction saved successfully');
        } else {
            console.error('Error saving winner transaction:', data.message);
        }
    } catch (error) {
        console.error('Error saving winner transaction:', error);
    }
}



//*********PRIKAZ SEKCIJA**********/

document.addEventListener('DOMContentLoaded', function() {
    // WINNERS SEKCIJA

    winnersNavItem.addEventListener('click', function(e) {
        e.preventDefault();
        winnersSection.style.display = 'block';
        winnersSection.scrollIntoView({ behavior: 'smooth' });
    });

    // CONTACT SEKCIJA
    contactNavItem.addEventListener('click', function(e) {
        e.preventDefault();
        contactSection.style.display = 'block';
        contactSection.scrollIntoView({ behavior: 'smooth' });
    });

    // ABOUT SEKCIJA

    aboutNavItem.addEventListener('click', function(e) {
        e.preventDefault();
        aboutSection.style.display = 'block';
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    });
});


//------------BANNER SECTION START----------//
// JavaScript za dupliciranje bannera kako bi kontinuirano tekli
//const bannerSlider = document.querySelector("#banner-slider");
//bannerSlider.innerHTML += bannerSlider.innerHTML; // Dupliciranje bannera

document.addEventListener('DOMContentLoaded', function() {
    const bannerSlider = document.querySelector('.banner-slider');

    if (!bannerSlider.classList.contains('duplicated')) { 
        bannerSlider.innerHTML += bannerSlider.innerHTML;
        bannerSlider.classList.add('duplicated');
    }
    
    
    // Dupliciraj sadržaj slidera za neprekidnu animaciju
    //bannerSlider.innerHTML += bannerSlider.innerHTML;

    const banners = document.querySelectorAll('.banner');
    const bannerWidth = banners[0].offsetWidth; // Pretpostavlja se da su svi banneri iste širine
     // Dodaj console.log ovde da proveriš vrednost bannerWidth
     console.log('Banner width:', bannerWidth);

      // Izračunaj broj banner-a u slideru nakon dupliranja
    const totalBanners = banners.length; // Ovo je broj banner-a u dupliranom slideru

    // Izračunaj širinu slidera
    const sliderWidth = totalBanners * bannerWidth;
    bannerSlider.style.width = `${sliderWidth/4}px`;


    // Apply the calculated width to the slider
    // bannerSlider.style.width = `${banners.length * bannerWidth * 2}px`;
    // Dodaj console.log ovde da proveriš širinu slidera
    // Izračunaj širinu slidera
    // const totalBanners = banners.length; // Ovo je broj banner-a u dupliranom slideru
    // const sliderWidth = totalBanners * bannerWidth;
    // bannerSlider.style.width = `${sliderWidth}px`;
    // Debugging
      console.log('Banner width:', bannerWidth);
      console.log('Slider width:', sliderWidth);

    let animationDuration = 15; // Adjust to control speed of animation


    // Start continuous animation
    bannerSlider.style.animation = `slider ${animationDuration}s infinite linear`;

    // Hover Effect using JavaScript to pause animation
    bannerSlider.addEventListener('mouseover', function() {
        bannerSlider.style.animationPlayState = 'paused';
    });

    bannerSlider.addEventListener('mouseout', function() {
        bannerSlider.style.animationPlayState = 'running';
    });
});


///////TELEGRAM WIDGET///////



/*///////////// WEBSOCKET PART START ////////////

// WebSocket dio
let socket;

function initializeWebSocket() {
    socket = new WebSocket('ws://localhost:3000');

    socket.onopen = () => {
        console.log('WebSocket konekcija uspostavljena');
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Primljena poruka od servera:', data);

        switch(data.type) {
            case 'newTransaction':
                handleNewTransaction(data);
                break;
            case 'notification':
                handleNotification(data.data);
                break;
            case 'welcome':
                console.log('Server:', data.data);
                break;
            case 'timerStarted':
                startTimer(data.duration);
                break;
            case 'timerUpdate':
                updateTimerDisplay(data.duration);
                break;
            case 'newWinner':
                addWinnerToSection(data.winnerAddress, data.amountWon);
                break;
            default:
                console.warn('Nepoznata vrsta poruke:', data.type);
        }
    };

    function handleNewTransaction(data) {
        updateDisplay(data.totalCollected);
        addressesAdded.push(data.address);
    }
    
    function startTimer(duration) {
        // Implementacija timera prema dobivenom trajanju
    }
    
    function addWinnerToSection(address, amountWon) {
        addWinner(winnersTableBody.children.length + 1, address, amountWon);
    }

    function handleNotification(message) {
        alert(message);
    }
    
    function updateTimerDisplay(seconds) {
        const formattedTime = formatTime(seconds);
        document.getElementById('timer').innerText = formattedTime;
    }

    socket.onclose = (event) => {
        console.warn('WebSocket veza zatvorena. Ponovno povezivanje za 5 sekundi...');
        setTimeout(initializeWebSocket, 5000); // Automatski pokušaj ponovnog povezivanja
    };

    socket.onerror = (error) => {
        console.error('WebSocket greška:', error);
        alert('Došlo je do problema s povezivanjem na server.');
    };
}

window.onload = async () => {
    try {
        const response = await fetch('http://localhost:3000/api/getCurrentState');
        if (!response.ok) throw new Error('Network response was not ok');

        const { totalCollected, walletAddresses, timerState, winners } = await response.json();

        updateDisplay(totalCollected);
        addressesAdded.push(...walletAddresses.map(address => address.wAddress));
        startTimer(timerState);
        winners.forEach((winner, index) => {
            addWinner(index + 1, winner.walletAddress, winner.winningValue);
        });
    } catch (err) {
        console.error('Greška prilikom dohvatanja trenutnog stanja:', err);
    }

    initializeWebSocket();
};

///////////////WEBSOCKET PART END////////////*/



//////////////PHONE WALLET CONNECT & TRANSACTION PART START//////////////

// Provjera je li korisnik na mobilnom uređaju
// Provjera je li korisnik na mobilnom uređaju
function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
}

// Provjera je li MetaMask instaliran
function isMetaMaskInstalled() {
    return typeof window.ethereum !== 'undefined';
}
// Preusmjeri korisnika na App Store ili Google Play
function redirectToAppStore() {
    if (isMobile()) {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Provjera je li korisnik na iOS-u ili Androidu
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Preusmjeravanje na App Store za iOS
            window.location.href = 'https://apps.apple.com/us/app/metamask/id1438144202';
        } else if (/android/i.test(userAgent)) {
            // Preusmjeravanje na Google Play za Android
            window.location.href = 'https://play.google.com/store/apps/details?id=io.metamask';
        } else {
            // Ako nije ni iOS ni Android, možeš dodati fallback URL ili obavijest
            alert('Please install MetaMask wallet on your mobile device.');
        }
    }
}


// Funkcija za povezivanje s MetaMask walletom
async function connectWalletm() {
    const token = getCookie('jwt');

    if (isMobile()) {
        if (!isMetaMaskInstalled()) {
            redirectToAppStore();
            return;
        }
    }

    try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        alert('Connected account: ' + accounts[0]);

        // Spremi adresu u bazu podataka
        const response = await fetch('apiUrl/api/connectWallet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ userAddress: accounts[0] })
        });

        const data = await response.json();
        if (data.success) {
            alert('Wallet connected and data saved');
        } else {
            alert('Error saving wallet data');
        }
    } catch (error) {
        if (error.code === 4001) {
            alert('Request was rejected. Please enable access to the wallet.');
        } else {
            alert('There was an error connecting to the wallet.');
        }
    }
}

// Event listener za gumb "Connect Wallet"
document.getElementById('connectWallet').addEventListener('click', connectWalletm);

// Funkcija za konverziju USD u ETH
const COINGECKO_API_URL_M = 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'; // Zamijeni s pravim API URL-om

// Funkcija za dobivanje cijene ETH
async function getEthPrice_m() {
    try {
        const response = await fetch(COINGECKO_API_URL_M);
        if (!response.ok) {
            throw new Error('Failed to fetch ETH price');
        }
        const data = await response.json();
        return data.ethereum.usd;
    } catch (error) {
        console.error('Error fetching ETH price:', error);
        return null;
    }
}

// Funkcija za konverziju USD u ETH
async function convertUsdToEthm(usdAmount) {
    const ethPrice = await getEthPrice_m();
    if (ethPrice === null) {
        throw new Error('Failed to convert USD to ETH due to missing ETH price');
    }
    return usdAmount / ethPrice;
}






// Funkcija za rukovanje transakcijama
async function handlePaymentm(usdAmount) {
    const token = getCookie('jwt');

    if (isMobile()) {
        if (!isMetaMaskInstalled()) {
            redirectToAppStore();
            return;
        }
    }

    const web3 = new Web3(window.ethereum);

    try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        const ethAmount = await convertUsdToEthm(usdAmount);
        const ethAmountWei = web3.utils.toWei(ethAmount.toString(), 'ether');

        if (confirm(`Do you want to proceed with the payment of ${ethAmount} ETH?`)) {
            // Preusmjeri korisnika u MetaMask aplikaciju
            const txParams = {
                from: accounts[0],
                to: '0xEAb7Be63c58DC0D6C181aBEf0785F74409097422', // Zamijenite s pravom adresom
                value: ethAmountWei,
                gas: '2000000', // Opcionalno: odredite gas
                gasPrice: await web3.eth.getGasPrice()
            };

            await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [txParams],
            });

            alert('Payment initiated successfully!');

            // Spremi transakciju u bazu
            await fetch('apiUrl/api/saveTransaction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    walletAddress: accounts[0],
                    paymentValue: usdAmount
                })
            });
        }
    } catch (error) {
        alert('Payment failed: ' + error.message);
    }
}

// Event listener za ticket gumbove
document.addEventListener('DOMContentLoaded', () => {
    const ticketButtons = document.querySelectorAll('.ticket-button');
    ticketButtons.forEach(button => {
        button.addEventListener('click', async (event) => {
            const usdValue = parseInt(event.target.getAttribute('data-value'));
            await handlePaymentm(usdValue);
        });
    });
});

//////////////PHONE WALLET CONNECT & TRANSACTION PART END//////////////